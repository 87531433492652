import { useState } from 'react';

function NewsletterForm({ status, message, onValidated }) {
  const [email, setEmail] = useState();
  const [error, setError] = useState(null);
  const onSubmit = (event) => {
    event.preventDefault();
    setError(null);
    if (!email) {
      setError('Please enter a valid email.');
      return;
    }
    email && onValidated({ EMAIL: email });
  };

  const getMessage = () => {
    if (error) {
      return (
        <p className="text-center lg:text-left lg:absolute mt-2 opacity-75 text-sm">
          {error}
        </p>
      );
    }
    switch (status) {
      case 'sending':
        return (
          <p className="text-center lg:text-left lg:absolute mt-2 opacity-75 text-sm">
            Sending...
          </p>
        );
      case 'success':
        return (
          <p className="text-center lg:text-left lg:absolute mt-2 opacity-75 text-sm">
            Thanks for subscribing!
          </p>
        );
      case 'error':
        return (
          <div
            className="text-center lg:text-left lg:absolute mt-2 opacity-75 text-sm"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        );
      default:
        return null;
    }
  };
  return (
    <form className="w-full lg:w-1/2">
      <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none">
        <input
          type="email"
          className="w-full appearance-none bg-purple-700 border border-purple-500 focus:border-purple-300 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-purple-400"
          onChange={(event) => setEmail(event?.target?.value ?? '')}
          placeholder="Your best email…"
          aria-label="Your best email…"
        />
        <a
          className="btn text-purple-600 bg-purple-100 hover:bg-white shadow"
          href="#0"
          onClick={onSubmit}
        >
          Subscribe
        </a>
      </div>
      {/* Success message */}
      {getMessage()}
    </form>
  );
}

export default NewsletterForm;
