import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../utils/Dropdown';

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Led Ape">
              <svg
                className="w-40 h-40 fill-current text-purple-600"
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1300 530"
              >
                <path
                  d="M301.32,92.29l34.15-23.71c-9.41-1.24-30.83-2.6-41.11-1.48C304.64,54,314.92,49.89,335.47,32.56c-54.35,8-146.59,36.77-185,66.11h0C77.27,131,25.83,204.36,25.83,289.2c0,114.52,93.72,208.24,208.24,208.24S442.19,403.72,442.19,289.2C442.19,198.19,383.07,120.4,301.32,92.29Z"
                  fill="#0059fb"
                  fill-rule="evenodd"
                />
                <path
                  d="M129.33,384.53c13,44.08,55,76.39,104.74,76.39s91.74-32.31,104.74-76.39C271.46,355.69,201.51,357.92,129.33,384.53Z"
                  fill="#fff"
                  fill-rule="evenodd"
                />
                <path
                  d="M315.78,197.22c-36.52.49-76.26,22.53-81.71,25.62-5.45-3.09-45.19-25.13-81.83-25.62-39.37-.5-56.34,22.16-45.07,59.55,4.71,17.82,19.31,26.24,37,29.46a125.13,125.13,0,0,1,42-25.32c-36.15,17.14-61,53.08-61,94.65,0,1.61,0,3.22.13,4.71,71.43-28.35,144-28.72,217.65,0,0-1.49.12-3.1.12-4.71,0-41.57-24.9-77.51-61-94.65a125.13,125.13,0,0,1,42,25.32c17.71-3.22,32.32-11.64,36.9-29.46C372.24,219.38,355.28,196.72,315.78,197.22ZM143.32,226.06l54.35,13.13C131.44,282.52,143.32,226.06,143.32,226.06ZM205,313a16.4,16.4,0,1,1,16.46-16.46A16.38,16.38,0,0,1,205,313Zm58.19,0a16.4,16.4,0,1,1,16.46-16.46A16.46,16.46,0,0,1,263.17,313Zm7.3-73.78,54.35-13.13S336.71,282.52,270.47,239.19Z"
                  fill="#fff"
                  fill-rule="evenodd"
                />
                <text
                  transform="translate(473.58 363.73)"
                  font-size="200"
                  fill="#0059fb"
                  font-family="Poppins-SemiBold, Poppins"
                  font-weight="600"
                >
                  Led Ape
                </text>
              </svg>
            </Link>
          </div>

          {/* Desktop navigation */}
          {/* <nav className="hidden md:flex md:flex-grow"> */}

          {/* Desktop menu links */}
          {/* <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link to="/features" className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">
                  Features
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">Pricing</Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">Blog</Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">About us</Link>
              </li> */}
          {/* 1st level: hover */}
          {/* <Dropdown title="Support"> */}
          {/* 2nd level: hover */}
          {/* <li>
                  <Link to="/contact" className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight">Contact us</Link>
                </li>
                <li>
                  <Link to="/help" className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight">Help center</Link>
                </li>
                <li>
                  <Link to="/404" className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight">404</Link>
                </li>
              </Dropdown>
            </ul> */}

          {/* Desktop sign in links */}
          {/* <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link to="/signin" className="font-medium text-purple-600 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out">Sign in</Link>
              </li>
              <li>
                <Link to="/signup" className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3">Sign up</Link>
              </li>
            </ul> */}

          {/* </nav> */}

          {/* Mobile menu */}
          {/* <div className="md:hidden"> */}

          {/* Hamburger button */}
          {/* <button ref={trigger} className={`hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button> */}

          {/*Mobile navigation */}
          {/* <nav id="mobile-nav" ref={mobileNav} className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out" style={mobileNavOpen ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: .8 } }>
              <ul className="bg-gray-800 px-4 py-2">
                <li>
                  <Link to="/features" className="flex text-gray-300 hover:text-gray-200 py-2">Features</Link>
                </li>
                <li>
                  <Link to="/pricing" className="flex text-gray-300 hover:text-gray-200 py-2">Pricing</Link>
                </li>
                <li>
                  <Link to="/blog" className="flex text-gray-300 hover:text-gray-200 py-2">Blog</Link>
                </li>
                <li>
                  <Link to="/about" className="flex text-gray-300 hover:text-gray-200 py-2">About us</Link>
                </li>
                <li className="py-2 my-2 border-t border-b border-gray-700">
                  <span className="flex text-gray-300 py-2">Support</span>
                  <ul className="pl-4">
                    <li>
                      <Link to="/contact" className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/help" className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2">Help center</Link>
                    </li>
                    <li>
                      <Link to="/404" className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2">404</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/signin" className="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center">Sign in</Link>
                </li>
                <li>
                  <Link to="/signup" className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out">Sign up</Link>
                </li>
              </ul>
            </nav> */}

          {/* </div> */}
        </div>
      </div>
    </header>
  );
}

export default Header;
